import React from "react";

const AboutUs = () => {
  return (
    <>
      <div className="container">
        <div className="d-flex pt--40 pb--40 justify-content-between ">
          <div>
            <div className="text--blue-1d3f font--36 sf--d-bold  mt--15 lh--52 w-80">
              We help businesses unify their communication. So they can focus on
              closing deals and delighting customers.
            </div>
          </div>
          <div className="">
            <iframe
              width="760"
              height="515"
              src="https://www.youtube.com/embed/9xwazD5SyVg?si=HIHr_omNLyQH8BeD"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <div className="bg--white radius--0-100 pt--40 pb--40 ">
        <div className="container">
          <div className="font--30 text--blue-1d3f sf--d-bold text--sm-center">
            The Nxatel Story
          </div>
          <div className="font--16 lh--35 sf--t-regular text--black text--sm-center">
            We started out 12 years ago by Lerato Dontache & Nqaba Dontache with
            the vision to (create great solutions to solve big problems and
            achieve greatness) to make phone support for businesses as easy to
            manage — accessible, inclusive and affordable. So we developed a
            communication solution that’s easy to set up, easy to use, and easy
            to scale. We believe voice is the most powerful way to communicate
            with customers and team members. But when the business communication
            is fragmented, teams waste time logging calls and searching for
            context when they could be strengthening relationships instead.
            We’re here to fix that.<br></br>
            <div className="mt--20">
              We empower businesses and they’re teams to collaborate faster and
              work smarter with their customers all in one single platform.
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center pt--40 pb--40 flex--sm-column">
        <div class="">
          <div class="mt--25 text-center">
            <img
              src="./../assets/images/about_page/1.png"
              alt="1"
              className="w-75 img-fluid"
            />
          </div>
        </div>

        <div class="text--sm-center ">
          <div class="w-75 m--sm-auto">
            <div
              className="
            text--gray-6971 font--16 sf--d-medium lh--35">
              <div className="mt--20">
                <b className="sf--d-bold font--18">NxaTel</b> provides an
                all-in-one, easy to use, cloud-based communication solution.
              </div>

              <div className="mt--20">
                <b className="sf--d-bold font--18">NxaTel</b> is a Modern
                Unified communication platform for Small and Medium businesses
                that allows you to get local numbers from over 10+ African
                countries to use on any device. It’s easy to set up; you just
                need 3 minutes, and you gain access to the cloud phone system,
                virtual meetings, team chats and cloud storing, all in a secure
                platform
              </div>

              <div>
                <b className="sf--d-bold font--18">NxaTel</b> was created to
                make business communication easy to manage – accessible,
                affordable, and simple to collaborate.
              </div>

              <div className="mt--20">
                <b className="sf--d-bold font--18">NxaTel</b> believes that
                conversations are powerful and should be frictionless. We want
                all businesses to get work done in one app to communicate with
                customers, prospects, candidates, and colleagues. It's designed
                to enable delightful moments of human connection.
              </div>
              <div className="mt--20">
                <b className="sf--d-bold font--18">NxaTel's</b> mission is to
                replace all other business communication apps and help teams
                save time and become more productive. With a mission to become
                the tool that enables all teams across a company (from non-tech
                to highly technical) to use one platform to get work done. That
                means sales, support, marketing, product, finance teams and more
                can manage all business communication on one App and customize
                it for each team.
              </div>
              <div className="mt--20">
                <b className="sf--d-bold font--18">NxaTel</b> believes that the
                future of work is flexible work. It’s more crucial than ever
                that businesses have mobility and seamless solution that allows
                employees to collaborate regardless of location and maximizes
                efficiency across all teams.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="pt--40 pb--40">
          <div className="d-flex gap--30 flex--sm-column justify-content--sm-center ">
            <div className="d-flex gap--15 ">
              <img
                src="./../assets/images/about_page/PURPOSE.png"
                alt="1"
                className="w--50 h--50 img-fluid"
              />
              <div className="d-flex flex-column">
                <div className="text--black-191b font--30 sf--d-bold ">
                  PURPOSE
                </div>
                <div className="text--gray-6971 font--22 mb--15 lh--35 w-75 w-sm-100">
                  To inspire people around the world to find great solutions to
                  solve big problems and achieve greatness
                </div>
              </div>
            </div>
            <div className="d-flex gap--15 ">
              <img
                src="./../assets/images/about_page/MISSION.png"
                alt="1"
                className="w--45 h--45 img-fluid"
              />

              <div className="d-flex flex-column">
                <div className="text--black-191b font--30 sf--d-bold ">
                  MISSION
                </div>
                <div className="text--gray-6971 font--22 mb--15 lh--35 w-75 w-sm-100">
                  To empower people to communicate simpler by democratizing
                  cloud communication to be more inclusive, accessible and
                  affordable
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex mt--40 w-100">
            <div className="d-flex gap--15 w-100">
              <img
                src="./../assets/images/about_page/VALUES.png"
                alt="1"
                className="w--45 h--45 img-fluid"
              />
              <div className="d-flex flex-column w-100">
                <div className="text--black-191b font--30 sf--d-bold ">
                  VALUES
                </div>
                <div className="d-grid grid--column-3 gap--25 mt--20 grid--sm-column-1">
                  <div className="d-flex p-3 bg--red-feec radius--15 align-items-center gap--10">
                    <img
                      src="./../assets/images/about_page/carbon_user-avatar-filled-alt.png"
                      alt="1"
                      className="w--50 h--50 img-fluid"
                    />
                    <div className="text--black-191b sf--t-bold font--22  lh--35">
                      Customer
                    </div>
                  </div>
                  <div className="d-flex p-3 bg--space-blue-eefb radius--15 align-items-center gap--10">
                    <img
                      src="./../assets/images/about_page/whh_commentlove.png"
                      alt="1"
                      className="w--50 h--50 img-fluid"
                    />
                    <div className="text--black-191b sf--t-bold font--22  lh--35">
                      Love
                    </div>
                  </div>
                  <div className="d-flex p-3 bg--pink-ffef radius--15 align-items-center gap--10">
                    <img
                      src="./../assets/images/about_page/simple-icons_trustpilot.png"
                      alt="1"
                      className="w--50 h--50 img-fluid"
                    />
                    <div className="text--black-191b sf--t-bold font--22  lh--35">
                      Integrity
                    </div>
                  </div>
                  <div className="d-flex p-3 bg--space-blue-f1ec radius--15 align-items-center gap--10">
                    <img
                      src="./../assets/images/about_page/dashicons_welcome-learn-more.png"
                      alt="1"
                      className="w--50 h--50 img-fluid"
                    />
                    <div className="text--black-191b sf--t-bold font--22  lh--35">
                      Learning
                    </div>
                  </div>
                  <div className="d-flex p-3 bg--gray-e7ef radius--15 align-items-center gap--10">
                    <img
                      src="./../assets/images/about_page/carbon_join-inner.png"
                      alt="1"
                      className="w--50 h--50 img-fluid"
                    />
                    <div className="text--black-191b sf--t-bold font--22  lh--35">
                      Oneness
                    </div>
                  </div>
                  <div className="d-flex p-3 bg--red-feec radius--15 align-items-center gap--10">
                    <img
                      src="./../assets/images/about_page/carbon_model-builder.png"
                      alt="1"
                      className="w--50 h--50 img-fluid"
                    />
                    <div className="text--black-191b sf--t-bold font--22  lh--35">
                      Innovate
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="founding-team d-flex flex--sm-column align-items--sm-center pt--100  pb--40 gap--130 gap--sm-25">
          <div className="text--sm-center">
            <div className="text--blue-1d3f font--45 sf--d-bold">
              Founding Team
            </div>
            <div className="text--blue-1d3f font--20 sf--d-regular">
              We are deeply invested in the people around us and the work we do
              with and for them. We strive to be deeply caring human beings,
              respected for our humanity as much as our business success.
            </div>
          </div>
          <div className="d-flex align-items-center  gap--10">
            <div className=" w-75 text-center">
              <img
                src="./../assets/images/about_page/founding-team1.png"
                alt="1"
                className="w-75 img-fluid"
              />
              <div className="text--blue-1d3f font--30 sf--d-bold mt--10 font--sm-20">
                Lerato Dontache
              </div>
              <div className="font--14 sf--d-semibold text--gray-6971">
                Co-Founder - CEO & Head of Product
              </div>
            </div>
            <div className=" w-75 text-center">
              <img
                src="./../assets/images/about_page/founding-team2.png"
                alt="1"
                className="w-75 img-fluid"
              />
              <div className="text--blue-1d3f font--30 sf--d-bold mt--10 font--sm-20">
                Nqaba Dontache
              </div>
              <div className="font--14 sf--d-semibold text--gray-6971">
                Co-Founder & Head of Business Dev
              </div>
            </div>
          </div>
        </div>
        <div className=" pt--80 pb--40 gap--130">
          <div>
            <div className="text--blue-1d3f font--45 sf--d-medium">
              Our Nxaies
            </div>
            <div className="text--blue-1d3f font--18 sf--d-regular d-flex align-items-center gap--10">
              Our Nxaies have HEART!
              <img
                src="./../assets/images/about_page/heart.png"
                alt="1"
                className="w--25 img-fluid"
              />
            </div>
          </div>
          <div className="d-flex gap--55 flex--sm-column gap--sm-15 ">
            <div className="w-100">
              <div className="about-box d-flex bg--white radius--4 p--15 gap--20 mt--50 mt--sm-15">
                <img
                  src="./../assets/images/about_page/Humble.png"
                  alt="1"
                  className="w--75 img-fluid radius--4"
                />

                <div className="text--blue-1d3f font--20 sf--d-medium mt--5">
                  Humble
                </div>
              </div>
              <div className="about-box d-flex bg--white radius--4 p--15 gap--20 mt--25">
                <img
                  src="./../assets/images/about_page/Adaptable.png"
                  alt="1"
                  className="w--75 img-fluid radius--4"
                />

                <div className="text--blue-1d3f font--20 sf--d-medium mt--5">
                  Adaptable
                </div>
              </div>
            </div>
            <div className="w-100 mt---100 m--sm-0">
              <div className="about-box d-flex bg--white radius--4 p--15 gap--20 mt--50 mt--sm-15">
                <img
                  src="./../assets/images/about_page/Enthusiasm.png"
                  alt="1"
                  className="w--75 img-fluid radius--4"
                />

                <div className="text--blue-1d3f font--20 sf--d-medium mt--5">
                  Enthusiasm
                </div>
              </div>
              <div className="about-box d-flex bg--white radius--4 p--15 gap--20 mt--50">
                <img
                  src="./../assets/images/about_page/Remarkable.png"
                  alt="1"
                  className="w--75 img-fluid radius--4"
                />

                <div className="text--blue-1d3f font--20 sf--d-medium mt--5">
                  Remarkable
                </div>
              </div>
            </div>
            <div className="w-100">
              <div className="about-box d-flex bg--white radius--4 p--15 gap--20 mt--50">
                <img
                  src="./../assets/images/about_page/Adaptable.png"
                  alt="1"
                  className="w--75 img-fluid radius--4"
                />

                <div className="text--blue-1d3f font--20 sf--d-medium mt--5">
                  Transparent
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt--40 pb--40">
          <div class="text--blue-1d3f font--45 sf--d-bold mb--10">Our Team</div>
          <img
            src="./../assets/images/about_page/our-team.png"
            alt="1"
            className="w-100 img-fluid radius--4"
          />
        </div>
        <div className="pt--40 pb--40">
          <div class="text--blue-1d3f font--45 sf--d-bold lh--45 mb--5">
            Our Product & Services
          </div>
          <div class="text--gray-7c87 font--20 sf--d-regular">
            Unified Communication as a Service Company [A network of networks]
          </div>
          <div className="d-grid grid--column-3 gap--25 gap--row-30 mt--30">
            <div class=" about-box bg--white radius--4 pt--50 pb--50 pr--50 pl--30 gap--20  ">
              <div class="text--blue-1d3f font--20 sf--t-regular fw--700 ">
                Phone
              </div>
              <div class="text--gray-7c87 font--18 sf--d-regular mt--5">
                Cloud phone system with 50+ robust features
              </div>
            </div>
            <div class=" about-box bg--white radius--4 pt--50 pb--50 pr--50 pl--30 gap--20  ">
              <div class="text--blue-1d3f font--20 sf--t-regular fw--700 ">
                Video
              </div>
              <div class="text--gray-7c87 font--18 sf--d-regular mt--5">
                Video meetings solution with HD video and screen sharing
              </div>
            </div>
            <div class=" about-box bg--white radius--4 pt--50 pb--50 pr--50 pl--30 gap--20  ">
              <div class="text--blue-1d3f font--20 sf--t-regular fw--700 ">
                Message
              </div>
              <div class="text--gray-7c87 font--18 sf--d-regular mt--5">
                SMS and Team messaging seamless collaboration with files, tasks,
                and more
              </div>
            </div>
            <div class=" about-box bg--white radius--4 pt--50 pb--50 pr--50 pl--30 gap--20  ">
              <div class="text--blue-1d3f font--20 sf--t-regular fw--700 ">
                SMS
              </div>
              <div class="text--gray-7c87 font--18 sf--d-regular mt--5">
                Team messaging and collaboration with files, tasks, and more
              </div>
            </div>
            <div class=" about-box bg--white radius--4 pt--50 pb--50 pr--50 pl--30 gap--20  ">
              <div class="text--blue-1d3f font--20 sf--t-regular fw--700 ">
                Business Cloud
              </div>
              <div class="text--gray-7c87 font--18 sf--d-regular mt--5">
                Domains, Hosting, Cloud Storage, Emails, Security
              </div>
            </div>
            <div class=" about-box bg--white radius--4 pt--50 pb--50 pr--50 pl--30 gap--20  ">
              <div class="text--blue-1d3f font--20 sf--t-regular fw--700 ">
                Nxatel App
              </div>
              <div class="text--gray-7c87 font--18 sf--d-regular mt--5">
                NEW! Improved, and all you need to work from anywhere
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex pt--40 pb--40 aliogn-items-center ">
        <div className="col-md-6 pl--195">
          <div className="text--blue-1d3f font--36 sf--t-bold mt--15 lh--52 w-80">
            THE COMPLETE BUSINESS TOOLBOX
          </div>
          <div className="text--blue-1d3f font--36 sf--t-bold mt--50 lh--52 w-80">
            NO CONTRACTS
          </div>
          <div className="text--gray-7c87 font--16 sf--t-regular  mt--30 lh--28 w-80 mb--30">
            We are so confident that you and your team will love Nxatel that we
            don’t have contracts
          </div>
          <div className="d-flex gap--15">
            <div className="btn bg--transparent text--orange radius--16-0 border--2 border--orange w--min-140">
              See pricing
            </div>
            <div className="btn bg--orange text--white radius--16-0 w--min-140">
              Try for 14 days
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <img
            src="./../assets/images/about_page/CONTRACTS.png"
            alt="1"
            className="w-75  img-fluid"
          />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
