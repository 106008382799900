import React, { Component } from "react";
import OtpInput from "react-otp-input";
import SimpleReactValidator from "simple-react-validator";
import PageHeader from "./PageHeader";
import ProgressBar from "./ProgressBar";
import ContactBar from "./ContactBar";
import swal from "sweetalert";
import { withRouter } from "../../Utils/withRouter";
import { store } from "../../Services";
import {
  cancelVerificationRequest,
  SendOTPThroughCall,
  UserSignUp,
  VerifyOTP,
} from "../../Services/Actions/LoginSignupActions";
import Footer from "../../Layout/Footer";
import Loader from "../../Component/Loader";
import Alerts from "../../Component/Alerts";
import { connect } from "react-redux";

const initialState = {
  otp: "",
  timer: 120, // secs
  phone: "",
  prefix: "",
  uniquecode: "",
  clicked: false,
  checked: false,
  cancelBtnDisable: true,
};

class NumberVerification extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      messages: {
        required: "Please enter the OTP to verify your phone number.",
      },
    });
    this.state = initialState;
    this.intervalID = 0;
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let {
      account_details: { phone, prefix },
    } = this.props;
    if (!phone) {
      this.props.navigate("/");
      return false;
    }
    prefix = prefix.includes("+") ? prefix : `+${prefix}`;
    // phone = `${prefix}${phone}`;
    this.setState({ phone, prefix }, () => {
      this.intervalID =
        this.intervalID === 0 &&
        setInterval(() => {
          this.setState(
            (prevstate) => ({ timer: prevstate.timer - 1 }),
            () => {
              const { timer } = this.state;
              if (timer === 0) {
                clearInterval(this.intervalID);
                this.intervalID = 0;
              }
              if (timer < 90) {
                this.setState({ cancelBtnDisable: false });
              }
            }
          );
        }, 1000);
    });
  };

  VerifyOTPMbl = () => {
    const { account_details } = this.props;
    const { otp } = this.state;
    let RequestId = localStorage.getItem("mbl_token");
    const validator = this.validator;
    if (!validator.allValid) {
      this.setState({ clicked: true });
      validator.showMessages();
      this.forceUpdate();
      return;
    }
    VerifyOTP({ code: otp, request_id: RequestId }, store.dispatch).then(
      async (responseData) => {
        /** OTP VERIFICATION */
        if (responseData === 1) {
          UserSignUp({ ...account_details, token: true }, store.dispatch).then(
            (response) => {
              /** USER SIGN UP  */
              if (response === 1) {
                clearInterval(this.intervalID);
                this.intervalID = 0;
                this.setState({ initialState });
              }
            }
          );
        } else {
          this.setState({ clicked: false });
        }
      }
    );
  };

  cancelOTPRequest = async () => {
    const { timer } = this.state;
    if (timer < 90) {
      let requestID = localStorage.getItem("mbl_token");
      const responseData = await cancelVerificationRequest(
        { requestID },
        store.dispatch
      );
      if (responseData === 1) {
        swal({
          icon: "success",
          title: "Success!",
          text: "OTP cancelled successfully.",
        }).then(() => {
          this.props.navigate("/");
        });
      }
    } else {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p>Verification can't be cancelled within the first 30 seconds.</p>`;
      swal({
        icon: "warning",
        title: `Warning!`,
        content: wrapper,
        className: "add-subscription",
      });
    }
  };

  componentWillUnmount() {
    clearInterval(this.intervalID);
    this.intervalID = 0;
  }

  render() {
    const { timer, otp, phone, clicked, prefix, cancelBtnDisable } = this.state;
    return (
      <>
        <div>
          <div className="sign-up-step-bar">
            <div className="container">
              <PageHeader />
              <ContactBar />
              <ProgressBar active="number-verify" />

              <div className="sign-otp text-center">
                <h2 className="font--30 text--black-191b fw-500">
                  Verify Phone Number
                </h2>
                <h6 className="font--16 text--black-191b mb-4 mt-3 fw--400">
                  {`Please enter the OTP you have received as an SMS on this number ${phone}`}
                </h6>
                <div className="form-group mb-1 otp d-flex justify-content-center">
                  <div className="d-flex align-items-center  otp-border">
                    <OtpInput
                      name="otp"
                      onChange={(value) =>
                        this.setState({
                          otp: value,
                        })
                      }
                      numInputs={4}
                      separator={<span>&nbsp;</span>}
                      value={otp}
                      className="form-control h-100 number-verify-otp"
                    />
                    {this.validator.message("otp", otp, "required")}
                  </div>
                </div>
                {timer > 0 && (
                  <p className="verification-duration">
                    <img src="./../assets/images/clock-alarm.svg" />
                    {timer} sec
                  </p>
                )}
                {timer === 0 && (
                  <button
                    type="button"
                    className="btn btn-verify"
                    onClick={() =>
                      SendOTPThroughCall(
                        { Number: phone, prefix },
                        store.dispatch
                      )
                    }>
                    Call Me
                  </button>
                )}
                <div className="d-flex justify-content-center mt-4">
                  <button
                    type="button"
                    className={`btn ${
                      cancelBtnDisable ? "btn-orange-back" : "btn-verify"
                    }`}
                    onClick={this.cancelOTPRequest}>
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-verify"
                    onClick={() => this.VerifyOTPMbl()}
                    disabled={clicked}>
                    {clicked ? "Please Wait..." : "Verify"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <Loader />
        <Alerts />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account_details: state?.users?.check_account ?? {},
});
export default connect(mapStateToProps)(withRouter(NumberVerification));
