import React, { useState } from "react";
import Fade from "@material-ui/core/Fade";
import { Link, Outlet } from "react-router-dom";
import Footer from "../Layout/Footer";
import Loader from "../Component/Loader";
import { withRouter } from "../Utils/withRouter";

function Home(props) {
  let activeUrl = window.location.pathname.replace("/", "");
  const [checked, setChecked] = useState(false);

  const ResponsiveNxaDropdown = () => {
    window.$("#navbarSupportedContentmob").removeClass("show");
  };

  return (
    <>
      <Fade in={checked} timeout={500}>
        <React.Fragment>
          <div className="homepage  h-100 overflow-auto">
            <div className=" text-center bg--orange radius--bottom-right-15 pt--8 pb--8">
              <div className="  d-flex align-items-center justify-content-center gap--10">
                <div className="text--white">
                  <span className="dm-sans fw-700">Announcement Header: </span>
                  Nedbank &amp; 702 Business Ignite 2020 - Winner
                </div>
                <div className="radius--50 pt--6 pb--6 pr--12 pl--12 bg--white text--gray-64 dm-sans fw--700 c--pointer">Read about it</div>
              </div>
            </div>
            <header className={`header ${activeUrl === "" ? "home-page-navbar" : "Pricing-header-bg"}`}>
              <div className="container">
                <nav className="first-nav navbar navbar-expand-lg d-none d-lg-flex ">
                  <Link to="/" style={{ zIndex: "9999" }} className="navbar-brand">
                    <img src="./../assets/images/homepagelogo.png" alt="logo" className="w--147" />
                    {/* <span className="we-hiring">We Hiring</span> */}
                  </Link>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto navigation gap--20">
                      {/* <li className="nav-item">
                          <Link to="/" className="nav-link">
                            Join Meeting
                          </Link>
                        </li> */}
                      <li className="nav-item">
                        <a className="nav-link sf--t-medium hover-bg--red-f037 text--purple-3f4e hover-text--white font--16 text-capitalize pt--8 pb--8 pr--10 pl--10  radius--5 h--min-44" href="https://support.nxa.tel/portal/">
                          Support
                        </a>
                      </li>
                      <li className="nav-item ">
                        <Link to="/login" className="nav-link sf--d-bold text--red-f037 hover-text--red-f037 font--16 text-capitalize border--2 border--red-f037 pt--8 pb--8 pr--30 pl--30 radius--5 bg--transparent">
                          Login
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/pricing" className="nav-link sf--d-bold text--white font--16 text-capitalize border--2 border--red-f037 pt--8 pb--8 pr--10 pl--10 radius--5 bg--red-f037 ">
                          Try for Free
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
                <nav className="second-nav navbar navbar-expand-lg navbar-bottom d-none d-lg-flex">
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto navigation-banner gap--48">
                      <li className="nav-item dropdown w--110 text-center">
                        <a className={`nav-link dropdown-toggle  after-none text--purple-3f4e font--16 text-capitalize sf--d-bold border--2 border--transparent border--orange-hover c--pointer ${["calls", "meetings", "message", "nxa-docs", "business-cloud", "business-internet"].includes(activeUrl) ? "active" : ""}`} href={void 0} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Product
                          <i className="fas fa-chevron-down ml-2 font--13"></i>
                        </a>
                        <div className="dropdown-menu p--5 border--none " aria-labelledby="navbarDropdown">
                          <Link className={`dropdown-item sf--t-medium  mr--30 font--16 text-capitalize pt--9 pb--9 pr--12 pl--12 border--bottom-1 border--gray-f0fb text--black-2129   ${activeUrl === "calls" ? "active" : ""}`} to="/calls">
                            Call
                          </Link>
                          <Link className={`dropdown-item sf--t-medium  mr--30 font--16 text-capitalize pt--9 pb--9 pr--12 pl--12 border--bottom-1 border--gray-f0fb text--black-2129   ${activeUrl === "meetings" ? "active" : ""}`} to="/meetings">
                            Meet
                          </Link>
                          <Link className={`dropdown-item sf--t-medium  mr--30 font--16 text-capitalize pt--9 pb--9 pr--12 pl--12 border--bottom-1 border--gray-f0fb text--black-2129   ${activeUrl === "message" ? "active" : ""}`} to="/message">
                            Message
                          </Link>
                          <Link className={`dropdown-item sf--t-medium  mr--30 font--16 text-capitalize pt--9 pb--9 pr--12 pl--12 border--bottom-1 border--gray-f0fb text--black-2129      ${activeUrl === "nxa-docs" ? "active" : ""}`} to="/nxa-docs">
                            NxaDox
                          </Link>
                        </div>
                      </li>
                      <li className="nav-item dropdown w--110 text-center">
                        <Link className={`nav-link dropdown-toggle after-none text--purple-3f4e font--16 text-capitalize sf--d-bold border--2 border--transparent border--orange-hover c--pointer ${["solution"].includes(activeUrl) ? "active" : ""}`} to={"/solution"}>
                          Solution
                        </Link>
                      </li>
                      <li className="nav-item w--110 text-center">
                        <Link className={`nav-link text--purple-3f4e font--16 text-capitalize sf--d-bold border--2 border--transparent border--orange-hover c--pointer ${["demo", "pricing"].includes(activeUrl) ? "active" : ""}`} to="/pricing">
                          Pricing
                        </Link>
                      </li>
                      <li className="nav-item w--110 text-center">
                        <Link className={`nav-link text--purple-3f4e font--16 text-capitalize sf--d-bold border--2 border--transparent border--orange-hover c--pointer ${activeUrl === "why-nxatel" ? "active" : ""}`} to="/why-nxatel">
                          Why Nxatel
                        </Link>
                      </li>
                      <li className="nav-item dropdown w--110 text-center">
                        <a className={`nav-link dropdown-toggle  after-none text--purple-3f4e font--16 text-capitalize sf--d-bold border--2 border--transparent border--orange-hover c--pointer ${["calls", "meetings", "message", "nxa-docs", "business-cloud", "business-internet"].includes(activeUrl) ? "active" : ""}`} href={void 0} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          About Us
                          <i className="fas fa-chevron-down ml-2 font--13"></i>
                        </a>
                        <div className="dropdown-menu p--5 border--none " aria-labelledby="navbarDropdown">
                          <a href="https://www.nxa.tel/blog" target="_self" className={`dropdown-item sf--t-medium  mr--30 font--16 text-capitalize pt--9 pb--9 pr--12 pl--12 border--bottom-1 border--gray-f0fb text--black-2129   ${activeUrl === "calls" ? "active" : ""}`} to="/calls">
                            Blog
                          </a>
                          <a href="https://careers.nxa.tel/jobs/Careers" target="_self" className={`dropdown-item sf--t-medium  mr--30 font--16 text-capitalize pt--9 pb--9 pr--12 pl--12 border--bottom-1 border--gray-f0fb text--black-2129   ${activeUrl === "meetings" ? "active" : ""}`} to="/meetings">
                            Careers
                          </a>
                        </div>
                      </li>
                      {/* <li className="nav-item w--110 text-center">
                        <Link
                          to="/about-us"
                          className={`nav-link text--purple-3f4e font--16 text-capitalize sf--d-bold border--2 border--transparent border--orange-hover c--pointer ${
                            activeUrl === "about-us" ? "active" : ""
                          }`}>
                          About Us
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </nav>

                {/* for mobile and ipad */}
                <nav className="navbar navbar-expand-lg navigation d-flex d-lg-none">
                  <Link className="navbar-brand" to="/">
                    {/* <img
                        src="./../assets/images/homepagelogohiring.svg"
                        alt="logo"
                      /> */}
                    <img src="./../assets/images/homepagelogo.png" alt="logo" className="w--110" />
                    {/* <span className="we-hiring">We Hiring</span> */}
                  </Link>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContentmob" aria-controls="navbarSupportedContentmob" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    <span className="navbar-toggler-icon"></span>
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  <div className="collapse navbar-collapse" id="navbarSupportedContentmob">
                    <ul className="navbar-nav ml-auto  bg--white radius--5 p--10 ">
                      <li className="nav-item dropdown">
                        <a className={`nav-link dropdown-toggle  d-flex align-items-center justify-content-between  ${["calls", "meetings", "message", "nxa-docs", "business-cloud", "business-internet"].includes(activeUrl) ? "active" : ""}`} href={void 0} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Product
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                          <Link className={`dropdown-item ${activeUrl === "calls" ? "active" : ""}`} to="/calls" onClick={() => ResponsiveNxaDropdown()}>
                            Call
                          </Link>
                          <Link className={`dropdown-item ${activeUrl === "meetings" ? "active" : ""}`} to="/meetings" onClick={() => ResponsiveNxaDropdown()}>
                            Meet
                          </Link>
                          <Link className={`dropdown-item ${activeUrl === "message" ? "active" : ""}`} to="/message" onClick={() => ResponsiveNxaDropdown()}>
                            Message
                          </Link>
                          <Link className={`dropdown-item ${activeUrl === "nxa-docs" ? "active" : ""}`} to="/nxa-docs" onClick={() => ResponsiveNxaDropdown()}>
                            NxaDox
                          </Link>
                        </div>
                      </li>
                      <li className={`nav-item ${["demo", "pricing"].includes(activeUrl) ? "active" : ""}`}>
                        <Link className={`nav-link`} to="/pricing" onClick={() => ResponsiveNxaDropdown()}>
                          Pricing
                        </Link>
                      </li>
                      <li className={`nav-item ${activeUrl === "why-nxatel" ? "active" : ""}`}>
                        <Link className={`nav-link`} to="/why-nxatel" onClick={() => ResponsiveNxaDropdown()}>
                          Why Nxatel
                        </Link>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="https://support.nxa.tel/portal/">
                          Support
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
                {/* end here */}
              </div>
            </header>
            <Outlet />
            <Footer />
          </div>
        </React.Fragment>
      </Fade>
      <Loader />
    </>
  );
}

export default withRouter(Home);
