import React, { useState, useEffect } from "react";
import Fade from "@material-ui/core/Fade";
import { withRouter } from "../../Utils/withRouter";
import { connect } from "react-redux";
import { store } from "../../Services";
import NxaFAQ from "../NxaFAQ";
import { CurrencyListing, PlansList, SelectedPlanDuringSignup } from "../../Services/Actions/userActions";
import PlanDetail from "./PlanDetail";

function Pricing(props) {
  const [duration, setDuration] = useState(1);
  const [selected_currency, setCurrency] = useState("R");
  const [user_allowed, setUserAllowed] = useState(0);
  const [planID, setPlanID] = useState(0);
  const [name, setName] = useState("");
  const [min_user, setMinUser] = useState(0);
  const [max_user, setMaxUser] = useState(0);
  const [plan_cost, setPlanCost] = useState("");
  const [video_calling_minutes, setVoiceCallingMinutes] = useState(0);
  const [voice_minute, setVoiceMinute] = useState(0);
  const [free_did_number, setFreeDidNumber] = useState(0);
  const [participants_for_video_confrence, setParticipantsForVideoConfernce] = useState(0);
  const [trial, setTrial] = useState("N");
  const [checked, setChecked] = useState(false);

  const {
    web_registration: { currency_list, plans_list },
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    CurrencyListing(store.dispatch);
    PlansList(store.dispatch);
    setChecked(true);
  }, []);

  useEffect(() => {
    const plan_id = planID;
    if (plans_list instanceof Array && plan_id === 0) {
      // Recomended scenerio here
      plans_list.map((value) => {
        if (value.name.includes("Professional")) {
          const {
            planID,
            name,
            plan_cost,
            video_calling_minutes,
            voice_minute,
            free_did_number,
            min_user,
            max_user,
            participants_for_video_confrence,
            min_user: user_allowed,
            plan_zoho_id,
          } = value;
          setUserAllowed(user_allowed);
          setPlanID(planID);
          setName(name);
          setMinUser(min_user);
          setMaxUser(max_user);
          setPlanCost(plan_cost);
          setVoiceCallingMinutes(video_calling_minutes);
          setVoiceMinute(voice_minute);
          setFreeDidNumber(free_did_number);
          setParticipantsForVideoConfernce(participants_for_video_confrence);
        }
      });
    }
  }, [plans_list]);

  const OnSelectPlanHanlder = (value, trial = "N") => {
    const { planID, name, plan_cost, video_calling_minutes, voice_minute, free_did_number, min_user, max_user, participants_for_video_confrence } =
      value;
    setUserAllowed(min_user);
    setPlanID(planID);
    setName(name);
    setMinUser(min_user);
    setMaxUser(max_user);
    setPlanCost(plan_cost);
    setVoiceCallingMinutes(video_calling_minutes);
    setVoiceMinute(voice_minute);
    setFreeDidNumber(free_did_number);
    setParticipantsForVideoConfernce(participants_for_video_confrence);
    setTrial(trial);
  };

  const setDemoPlan = () => {
    props.navigate("demo");
  };

  const onPlanSelectedDuringSignup = (value, data) => {
    let sendData = {
      planID,
      duration,
      plan_users: user_allowed,
      currency: selected_currency,
      trial: data === "trial" ? "Y" : trial,
    };
    SelectedPlanDuringSignup(sendData, store.dispatch);
    this.props.navigate("/signup");
  };

  const CollectionList = {
    duration,
    planID,
    name,
    plan_cost,
    video_calling_minutes,
    voice_minute,
    free_did_number,
    min_user,
    max_user,
    participants_for_video_confrence,
    plans_list,
    selected_currency,
    OnSelectPlanHanlder,
    setDemoPlan,
    onPlanSelectedDuringSignup,
  };

  return (
    <>
      <Fade in={checked} timeout={500}>
        <React.Fragment>
          <div className="pricing-step pricing-signup pricing-page">
            <div className="pricing-after pricing-box-section pt-5">
              <div className="container">
                <div className="Steps-price pricing-page">
                  <div className="row">
                    <div className="col-md-6"> </div>
                    <div className="col-md-6 text-right">
                      <div className="btn-group btn-group-pricing " role="group" aria-label="Basic example">
                        <button type="button" className={`btn btn-secondary ${duration === 1 ? "active" : ""}`} onClick={() => setDuration(1)}>
                          Monthly
                        </button>
                        <button
                          type="button"
                          className={`fw-700 btn btn-secondary ${duration === 12 ? "active" : ""}`}
                          onClick={() => setDuration(12)}>
                          12-Month Save Up to 18%
                        </button>
                        <button
                          type="button"
                          className={`fw-700 btn btn-secondary ${duration === 24 ? "active" : ""}`}
                          onClick={() => setDuration(24)}>
                          24-Month Save Up to 20%
                        </button>
                      </div>
                      <div className="form-group currency currency-div pt-3 ml-auto mr-2">
                        <select
                          className="form-control  color-293F"
                          id="exampleFormControlSelect1"
                          name="selected_currency"
                          value={selected_currency}
                          onChange={(e) => setCurrency(e.target.value)}>
                          <option value="">Currency</option>
                          {currency_list &&
                            currency_list.map((value, index) => {
                              return (
                                <option key={index} value={value.symbol}>
                                  {value.currencyCode}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="pricing pricing-palden">
                  <PlanDetail {...CollectionList} />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-6 free-trial-div">
                  <h5 className="12 color-696871 fw--400">Your 14-day Free Trial includes:</h5>
                  <span className="font--10">
                    - Full use of the Nxatel cloud phone system for up to 14 days
                    <br /> - Maximum of 2 users <br />- 10 min talk time per user
                    <br /> - 3 SMS
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* ----- */}
          <NxaFAQ />
        </React.Fragment>
      </Fade>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    web_registration: state?.users ?? {},
  };
};
export default connect(mapStateToProps)(withRouter(Pricing));
